.accordionContent {
  overflow: hidden;
  transition: max-height 0.5s ease;
  max-height: 0;
}

.accordionContent.visible {
  max-height: 1000px; /* 充分に大きな値にする */
}

.move-time-label {
  writing-mode: horizontal-tb !important; /* 強制的に横書きモード */
  text-orientation: mixed; /* 必要であれば指定 */
  
  background-color: rgba(255, 255, 255, 0.9);
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  box-shadow: 0 2px 6px rgba(0,0,0,0.3);

  /* まずは文字が1行に並ぶか確認するために折り返し不可に */
  white-space: nowrap;
  display: inline-block; /* インラインブロックで横幅に応じて拡張 */
}




.clock-icon {
  margin-right: 5px; /* アイコンと文字の間に余白 */
}

/* 地図コンテナのレスポンシブな高さ */
.responsive-map {
  height: 400px;
  width: 100%;
}

@media (max-width: 767px) {
  .responsive-map {
    height: 200px;
  }
}

.waypoints-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin-top: 20px;
  width: 100%;
}

.waypoints-item {
  display: flex;
  align-items: center;
  width: 100%;
}

.waypoints-label {
  flex: 0 0 30px; /* ラベルの固定幅 */
  font-weight: bold;
  text-align: left;
}

.waypoints-content {
  background-color: #f9f9f9;
  padding: 8px 12px;
  border-radius: 5px;
  border: 1px solid #ddd;
  width: 100%;
  text-align: left; /* 左寄せを強制 */
}

.waypoints-content p {
  margin: 0; /* 不要なマージンを削除 */
}

.waypoints-item:hover .waypoints-content {
  background-color: #e6f7ff;
  border-color: #91d5ff;
}

@media (max-width: 576px) {
  .waypoints-label {
    flex: 0 0 25px;
    font-size: 14px;
  }

  .waypoints-content {
    padding: 6px 10px;
    font-size: 14px;
  }
}

/* テーブル全体 */
.delivery-plan-table,
.delivery-plan-detail-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  overflow: hidden;
}

/* ヘッダー */
.table-header {
  background-color: #f8f9fa;
  font-weight: bold;
  display: flex;
}

.table-header .table-row {
  display: flex;
  width: 100%;
}

/* ボディ */
.table-body {
  display: flex;
  flex-direction: column;
}

/* 各行 */
.table-row {
  display: flex;
  align-items: center;
  border-top: 1px solid #dee2e6;
}

.table-row:nth-child(odd):not(.status-pending) {
  background-color: #f0f5fd; /* 白 */
}

.table-row:nth-child(even):not(.status-pending) {
  background-color: #dfeeff; /* 灰色 */
}

/* セル */
.table-cell {
  padding: 12px;
  flex: 1;
  word-break: break-word;
}

/* 特定のセル幅を指定 */
.table-cell:nth-child(1) {
  flex: 0.5;
}

.table-cell:nth-child(2),
.table-cell:nth-child(3),
.table-cell:nth-child(8) {
  flex: 1;
}

.table-cell:nth-child(4),
.table-cell:nth-child(5),
.table-cell:nth-child(6),
.table-cell:nth-child(7) {
  flex: 1.5;
}

/* ステータスが未実施の行に黄色の背景色を適用 */
.table-row.status-pending {
  background-color: #ffe3e3; /* Bootstrapのwarning背景色 */
}

/* 行にホバー効果を追加 */
.table-row:hover {
  background-color: #e2e6ea;
}

/* 操作ボタンの配置を調整 */
.table-cell .btn {
  margin-right: 5px;
}

/* レスポンシブ対応 */
@media (max-width: 768px) {
  .d-none.d-md-block {
    display: none !important;
  }
}

/* 行にホバー効果を追加 */
.table-row:hover {
  background-color: #dbe0ff;
}

/* 操作ボタンの配置を調整 */
.table-cell .btn {
  margin-right: 5px;
}

/* 新規計画アイコン用 */
.new-plan-indicator {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 5px;
  border-radius: 50%;
  background-color: red;
}

.arrow-row {
  align-self: center; /* 親が display:flex; flex-direction:column; でも、子1つだけ中央寄せ */
  width: 100%;
  justify-content: center; /* Bootstrap的に言えばこちらでもよい */
  text-align: center;      /* 念のため */
}
