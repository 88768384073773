.badge-custom {
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 5px 8px;
    font-size: 0.75rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 20px;
    height: 20px;
  }
  
  .badge-error {
    background-color: orange;
    color: white;
    border-radius: 50%;
    padding: 5px 8px;
    font-size: 0.75rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 20px;
    height: 20px;
    cursor: default;
  }
  
  .badge-error:hover::after {
    content: attr(title);
    position: absolute;
    background-color: black;
    color: white;
    padding: 5px;
    border-radius: 4px;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    z-index: 1000;
  }
  