/* src/components/CustomGoogleMap.css */

.info-window {
    font-family: Arial, sans-serif;
    font-size: 14px;
  }
  
  .cluster-info-window ul {
    padding-left: 20px;
    margin: 0;
  }
  